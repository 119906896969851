export const FAQBASE : Faq = {
  siege : {
    position : {
      title : `Où se situe Codeloccol ?`,
      content: `<p>Le campus de Codeloccol se situe à Sadoré (35 km de Niamey) au sein de la cité de
      l'innovation. Il propose un cadre agréable , propice au travail intense en toute sérénité.</p>
    <p></p>`
    },
    condition : {
      title : "Serons-nous hébergés et nourris ?",
      content: `<p>Codeloccol est doté de dortoires (filles et garçons séparés) et d'une cantine pour la restauration. Ceux qui le souhaite
      peuvent s'hébergé sur place selon la disponibilité</p>`
    },
    transport : {
      title : "Comment est organisé le transport ?",
      content: `<p>Il y'a une navette journalière vers la cité de l'innovation du Lundi au Vendredi</p>`
    },
    learn : {
      title : `Comment est le cadre d'apprentissage ?`,
      content: `<p>Codeloccol offre un environnement d'apprentissage innovant. Nous mettons à disposition des
      aprennants ce qui se fait de mieux en terme de technologie pour apprendre les métiers du Digital</p>
    <p>Nous avons notamment : </p>
    <ul>
      <li>Une salle de cours avec une capacité de 60 aprenants équipée d'ordinateur MAC.</li>
      <li>Une connexion internet haut débit (100 Mb/s)</li>
      <li>Un laboratoire de fabication numérique (Fablab)</li>
      <li>Une salle de détente</li>
      <li>etc...</li>
    </ul>`
    }
  },
  access : {
    career : {
      title : `Quel diplôme dois-je avoir pour accéder à Codeloccol`,
      content: `<p>Notre ambition est de permettre à tous d'avoir accès au coding.</p>
      <p>Par conséquent nous n'exigeons pas de diplome à l'entrée. Il suffit pour accéder à Codeloccol
        d'avoir la passion du coding et passer nos différents tests d'entrée.</p>`
    },
    test : {
      title : `Que dois-je préparer pour le test d'entrée ?`,
      content: `<p>L'admission à codeloccol se fait en trois phase :</p>
      <ul>
        <li>Une candidature en ligne</li>
        <li>Un test psychotechnique à l'issue duquel les meilleurs seront préselectionnés</li>
        <li>Un entretien de masse à l'issue duquel la selection finale est faite</li>
        <li>Un "piscine" en ligne durant laquelle les candidats auront déjà à s'immerger dans l'univers du
          coding à travers des projets pour une durée de 3 à 4 semaines</li>
        <li>Et enfin un entretien à l'issue duquel la selection finale est faite</li>
        
      </ul>
      <p></p>`
    },
    level : {
      title :`Je suis lycéen/étudiant/professionnel et je souhaite avoir accès à
      Codeloccol, est ce possible ?`,
      content: `<p>L'apprentissage à Codeloccol via son parcours principal requiert 100% de votre temps. Il est
      impossible de conbiner l'apprentissage à Codelocol et tout autre activité au risque de perdre les
      deux</p>
    <p>Cependant, nous aurons au cours de l'année des formations sous les formats de bootcamp, formation en
      ligne et formation à la carte. Ces dernières sont conçues justement pour des personnes ne pouvant pas
      consacrer l'entiéreté de l'année et souhaitant quand même apprendre à coder. </p>`
    }
  },
  parcours : {
    formation: {
      title : "Quelle est la durée de la formation ?",
      content: `<p>L'apprentissage à Codeloccol dure 12 mois, auquels s'ajoute 6 mois de stage professionnel pendant lesquels l'apprenant s'engage à travailler sur des projets de l'ANSI et ses partenaires</p>`
    },
    time : {
      title : "Quelle Diplôme aurais-je à la fin de ma formation ?",
      content: ` <p>Codeloccol n'est pas un établissement d'enseignement supérieur. Nous ne délivrons pas de diplôme de
      l'enseignement supérieur. Nous sommes un centre de perfectionnement au métier de codeur.</p>
    <p>A la fin de sa formation l'apprenant obtient une certification Codeloccol qui atteste de son savoir
      faire. L'apprenant qui le souhaite peut entreprendre une procédure de validation des acquis de l'expérience auprès du Ministère en charge de l'enseignement professionnel.</p>`
    },
    learn : {
      title : `Comment se fait l'apprentissage à Codeloccol ?`,
      content: `<p>Codeloccol propose une formation 100% pratique et intense</p>
      <p>Nous avons une pédagogie innovante basée sur l'autoapprentissage, l'apprentissage par les pairs et les projets pratiques. Nous avons une liste de projets que les apprenants
        doivent terminer durant leur parcours.</p>
      <p>Chaque block renferme des instructions et un certains nombres de projet à
        terminer avant un délai imparti. Ensuite les projets sont évalués et l'apprenant passe au niveau
        suivant après avoir maitrisé ce niveau ci</p>
      <p>Pour y arriver les apprenants apprennent par eux même (self learning) les concepts de base. Ils
        appliquent en réalisant les projets liés aux blocks. Durant la réalisation des projets l'apprenant
        fait appel à ses pairs pour mieux comprendre ses zones d'ombres (peer learning). Au cas échéant il peut faire appel
        aux coachs disponibles.</p>`
    }
  },
  frais : {
    money : {
      title : "Combien me coutera la formation à Codeloccol",
      content: `<p>Les couts de la formation pour un apprenant s'élève à 2.000.000 de Francs CFA. L'ANSI prend en charge 85% des frais et les 15% (300.000 Francs CFA) sont à la charge de l'apprenant.</p>`
    },
    bourse : {
      title : "Puis-je avoir accès à une bourse ?",
      content: `<p>Nous avons grâce à notre partenariat avec l'Agence National pour la Société de l'Information (ANSI) des bourses disponibles<!--<a
      href="/bourseUR.pdf" target="_blank">Trouvez ici les critères d'accès</a>--></p>
  <p>L'ensem.</p>`
    },
    paiement : {
      title : "Y'a t-il des facilités de paiement ?",
      content: `
        <p>L'apprenant non boursiers doit s'acquitter de 15% des frais de formation avant la rentrée. Les modalité de paiements sont : </p>
      <ol>
        <li> 1. Paiement de 150.000 avant la rentrée</li>
        <li> 2. Le montant restant est recquis dans les 6 mois en raison d'un versement de 25.000 par mois</li>
      </ol>
      
      `
    }
  }
};
export default FAQBASE;
